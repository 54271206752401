import { useEffect, useState, useRef } from 'react';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';


import { useMediaQuery, Popover, DialogTitle, DialogContent, Button, Grid2, Checkbox, FormControlLabel, FormControl, FormGroup, Select, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import TextInput from 'components/Input/Text';
import { Form, Field } from 'react-final-form';
import { useExperiences } from 'core/contexts/experience';
import { useTranslation } from 'core/contexts/localization';

import FutureEventsModal from './FutureEventsModal';

import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import DatePickerWithInput from 'core/libs/core-ui/components/Input/DatePickerWithInput';

// import { TimeZoneFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useStyles } from './styles';
import { TimePickerHour, TimePickerMinutes } from 'core/libs/core-ui/components/Input/TimePicker';

function getOffsetForLoc(loc, date = new Date()) {
    // Use Intl.DateTimeFormat to get offset
    let opts = { hour: 'numeric', timeZone: loc, timeZoneName: 'short' };
    let getOffset = (lang) =>
        new Intl.DateTimeFormat(lang, opts).formatToParts(date).reduce((acc, part) => {
            acc[part.type] = part.value;
            return acc;
        }, {}).timeZoneName;
    let offset = getOffset('en');
    // If offset is an abbreviation, change language
    if (!/^UCT|GMT/.test(offset)) {
        offset = getOffset('fr');
    }
    // Remove GMT/UTC
    return offset.substring(3);
}
function TimeZoneFormattedUtils(tz) {
    return `${tz} (GMT${getOffsetForLoc(tz)})`;
}
const TIME_ZONES = Intl.supportedValuesOf('timeZone').map((tz) => {
    return { id: tz, name: TimeZoneFormattedUtils(tz) };
});

const dayExtractor = (rruleString) => {
    // Parse the RRULE
    const rule = RRule.fromString(rruleString);

    // Function to get the days of the week from the RRULE
    const getDaysOfWeekFromRRule = (rule) => {
        const byDay = rule.options.byweekday;
        const daysOfWeek = {
            0: 'MO',
            1: 'TU',
            2: 'WE',
            3: 'TH',
            4: 'FR',
            5: 'SA',
            6: 'SU',
        };

        return byDay.map((day) => daysOfWeek[day.toString()]);
    };
    // Get the days of the week
    return getDaysOfWeekFromRRule(rule);
};

const recurringLimitExtractor = (rruleString) => {
    const rule = RRule.fromString(rruleString);

    const { count = 0, until = false } = rule.options;

    if (count) {
        return { type: 'count', value: count };
    }
    if (until) {
        return { type: 'until', value: until };
    }
    return false;
};

export const ExperienceScheduleForm = ({
    range,
    open,
    onDismiss,
    onSave,
    onSelect,
    position: { x, y },
    start,
    end,
    setStart,
    setEnd,
    calendarTz,
    expId, // This should be context?
    subExpId,
    clickedEventValues,
    clickedSchedule,
    getEvents,
    ...props
}) => {
    const { experience, experienceSchedule, getExperienceSchedule, addExperienceSchedule, updateExperienceSchedule, deleteExperienceSchedule } = useExperiences();
    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const classes = useStyles();
    const { t: translate } = useTranslation();
    const [values, setValues] = useState(clickedEventValues);
    const [tz, setTz] = useState(calendarTz);
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverRef = useRef();
    const [showDeleteFutureEventsModal, setShowDeleteFutureEventsModal] = useState(false);
    const [showSaveFutureEventsModal, setShowSaveFutureEventsModal] = useState(false);
    // array of selected weekdays, following JS getDay() convention, 0 is sunday & 6 is saturday
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [selectedRecurrenceEnd, setSelectedRecurrenceEnd] = useState(false);
    const [oldStartUTC, setOldStartUTC] = useState(null);
    const [oldEndUTC, setOldEndUTC] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startHour, setStartHour] = useState('00');
    const [endHour, setEndHour] = useState('00');
    const [startMinutes, setStartMinutes] = useState('00');
    const [endMinutes, setEndMinute] = useState('00');

    const handleInputChange = (e, isNumber = false, isInteger = false) => {
        let { name, value } = e.target;
        if (isNumber && value.length === 0) {
            isNumber = false;
        }
        setValues({
            ...values,
            [name]: !isNumber ? value : isInteger ? parseInt(value) : parseFloat(value),
        });
    };

    const getDaysOfWeekFromRRule = (day) => {
        const daysOfWeek = {
            0: 'MO',
            1: 'TU',
            2: 'WE',
            3: 'TH',
            4: 'FR',
            5: 'SA',
            6: 'SU',
        };

        return daysOfWeek[day.toString()];
    };

    // for debugging purposes only
    useEffect(() => {
        setValues({ ...clickedEventValues, count: RRule.fromString(clickedEventValues.rRule).options.count || 0, until: RRule.fromString(clickedEventValues.rRule).options.until?.toDateString() || `${translate('copyScheduleModal.date')}` });

        if (clickedEventValues.rRule) {
            if ((Date.parse(values.startUTC) || Date.parse(start)) && (values.timezone || tz)) {
                const utcDate = Date.parse(values.startUTC) ? DateTime.fromISO(values.startUTC, { zone: 'UTC' }) : DateTime.fromISO(start.toISOString(), { zone: 'UTC' });

                const adjustedDate = utcDate.setZone(values.timezone || tz, { keepLocalTime: false });

                const offsetHours = utcDate.hour - adjustedDate.hour + adjustedDate.offset / 60;

                const adjustedWeekDays = clickedEventValues.rRule
                    ? dayExtractor(clickedEventValues.rRule)?.map((day) => {
                          const dayIndex = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].indexOf(day);

                          let adjustedIndex = dayIndex;

                          if (offsetHours < 0) {
                              adjustedIndex = (dayIndex - 1) % 7;
                          }
                          if (offsetHours > 0) {
                              adjustedIndex = (dayIndex + 1) % 7;
                          }
                          return ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][adjustedIndex];
                      })
                    : [];

                const adjustedRecurringLimit = clickedEventValues.rRule ? recurringLimitExtractor(clickedEventValues.rRule) : false;

                setSelectedWeekDays([...adjustedWeekDays]);
                setSelectedRecurrenceEnd(adjustedRecurringLimit);
            }
        } else {
            setSelectedWeekDays([]);
        }
    }, [clickedEventValues, start]);

    useEffect(() => {
        if (!values.timezone && tz) {
            setValues({ ...values, timezone: tz });
        }
    }, [values, tz]);

    useEffect(() => {
        setTz(calendarTz);
    }, [calendarTz]);

    useEffect(() => {
        if ((Date.parse(values.startUTC) || Date.parse(start)) && (values.timezone || tz)) {
            const utcDate = Date.parse(values.startUTC) ? DateTime.fromISO(values.startUTC) : DateTime.fromISO(start.toISOString());
            const adjustedDate = utcDate.setZone(values.timezone || tz);
            setStartDate(adjustedDate.toFormat('ccc LLL dd yyyy'));
            setStartHour(adjustedDate.toISOTime().substring(0, 2));
            setStartMinutes(['00', '15', '30', '45'].includes(adjustedDate.toISOTime().substring(3, 5)) ? adjustedDate.toISOTime().substring(3, 5) : '00');
            setOldStartUTC(start && Date.parse(start) ? start.toISOString() : null);
        }
    }, [start]);

    useEffect(() => {
        if ((Date.parse(values.endUTC) || Date.parse(end)) && (values.timezone || tz)) {
            const utcDate = Date.parse(values.endUTC) ? DateTime.fromISO(values.endUTC) : start.getTime() === end.getTime() ? DateTime.fromISO(end.toISOString()).plus({ hours: 1 }) : DateTime.fromISO(end.toISOString());
            const adjustedDate = utcDate.setZone(values.timezone || tz);
            setEndDate(adjustedDate.toFormat('ccc LLL dd yyyy'));
            setEndHour(adjustedDate.toISOTime().substring(0, 2));
            setEndMinute(['00', '15', '30', '45'].includes(adjustedDate.toISOTime().substring(3, 5)) ? adjustedDate.toISOTime().substring(3, 5) : '00');
            setOldEndUTC(end && Date.parse(end) ? end.toISOString() : null);
        }
    }, [end]);

    const validatePositiveIntegerInput = (value) => {
        const regex = /^[1-9][0-9]*$/;
        return regex.test(value);
    };

    const handleSubmit = async () => {
        if (!tz) {
            return;
        }
        let data = loadDateIntoValues();
        if (!data.startUTC) {
            return;
        }
        data.timezone = tz;
        const success = await addExperienceSchedule({
            experienceDetailId: expId,
            ...data,
        });
        if (success) {
            onDismiss();
            onSave({ data: success, event: 'onSave' });
        }
    };

    const generateDateRange = () => {
        if (!Date.parse(startDate) || !Date.parse(endDate)) {
            alert('invalid Date');
            return;
        }
        const newStartDate = new Date(startDate);
        const newStartDateYear = newStartDate.getFullYear();
        const newStartDateMonth = String(newStartDate.getMonth() + 1).padStart(2, '0');
        const newStartDateDay = String(newStartDate.getDate()).padStart(2, '0');
        const newEndDate = new Date(endDate);
        const newEndDateYear = newEndDate.getFullYear();
        const newEndDateMonth = String(newEndDate.getMonth() + 1).padStart(2, '0');
        const newEndDateDay = String(newEndDate.getDate()).padStart(2, '0');
        const overrideStartZone = DateTime.fromISO(`${newStartDateYear}-${newStartDateMonth}-${newStartDateDay}T${startHour}:${startMinutes}:00`, { zone: values.timezone });
        const overrideEndZone = DateTime.fromISO(`${newEndDateYear}-${newEndDateMonth}-${newEndDateDay}T${endHour}:${endMinutes}:00`, { zone: values.timezone });
        if (overrideStartZone >= overrideEndZone) {
            alert('Por favor ingrese una fecha de finalización mayor a la fecha de inicio.');
            return null;
        }
        let oldStartUTCValue = null;
        let oldEndUTCValue = null;
        const luxonOldStartUTC = DateTime.fromISO(oldStartUTC);
        const luxonOldEndUTC = DateTime.fromISO(oldEndUTC);
        if (overrideStartZone.toMillis() !== luxonOldStartUTC.toMillis()) {
            oldStartUTCValue = oldStartUTC;
        }
        if (overrideEndZone.toMillis() !== luxonOldEndUTC.toMillis()) {
            oldEndUTCValue = oldEndUTC;
        }
        const range = {
            startUTC: overrideStartZone.toUTC().toString(),
            endUTC: overrideEndZone.toUTC().toString(),
            oldStartUTC: oldStartUTCValue,
            oldEndUTC: oldEndUTCValue,
            ...generateRRule(overrideStartZone.toUTC()),
        };
        return range;
    };
    const generateRRule = (utcDate) => {
        if (!selectedWeekDays || !selectedWeekDays.length) {
            return { repeat: false };
        } //backend will handle default weekly
        // const utcDate = Date.parse(values.startUTC)
        //     ? DateTime.fromISO(values.startUTC, { zone: 'UTC' })
        //     : DateTime.fromISO(start.toISOString(), { zone: 'UTC' });

        const adjustedDate = utcDate.setZone(values.timezone || tz, {
            keepLocalTime: false,
        });

        const offsetHours = utcDate.hour - adjustedDate.hour + adjustedDate.offset / 60;

        const adjustedWeekDays = selectedWeekDays.map((day) => {
            const dayIndex = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'].indexOf(day);
            let adjustedIndex = dayIndex;

            if (offsetHours < 0) {
                adjustedIndex = (dayIndex + 1) % 7;
            }
            if (offsetHours > 0) {
                adjustedIndex = (dayIndex - 1) % 7;
            }

            return ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][adjustedIndex];
        });

        // Create the RRULE instance
        const rrule = new RRule({
            freq: RRule.WEEKLY,
            byweekday: adjustedWeekDays.map((day) => RRule[day]),
        });

        // Generate the RRULE string
        return {
            repeat: true,
            rRule: rrule.toString().substring(6), // We remove 'RULE:'
        };
    };
    const loadDateIntoValues = () => {
        const data = {
            ...values,
            ...generateDateRange(),
        };
        return data;
    };

    const onCloseFutureEventsModal = () => {
        if (showDeleteFutureEventsModal) setShowDeleteFutureEventsModal(false);
        else if (showSaveFutureEventsModal) setShowSaveFutureEventsModal(false);
        setAnchorEl(null);
        onDismiss();
    };

    const handleChecked = (e, value) => {
        let prev = selectedWeekDays;
        let itemIndex = prev.indexOf(value);
        if (itemIndex !== -1) {
            prev.splice(itemIndex, 1);
        } else {
            prev.push(value);
        }
        setSelectedWeekDays([...prev]);
    };

    return (
        <>
            <Popover
                ref={popoverRef}
                onClose={onDismiss}
                open={open}
                anchorReference="anchorPosition"
                anchorPosition={{ top: y, left: x }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                disableRestoreFocus
            >
                <DialogTitle>
                    <Grid2 container justifyContent="space-between">
                        <Grid2>
                            <h3>{translate('scheduleForm.receiveTripersHeader')}</h3>
                        </Grid2>
                        <Grid2>
                            <button
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    onDismiss();
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </Grid2>
                    </Grid2>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {/* {loading ? (
                        <CircularProgress />
                    ) : ( */}
                    <>
                        <Form
                            initialValues={{ ...values }}
                            onSubmit={handleSubmit}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <Grid2 container spacing={1}>
                                        <Grid2 size={12} container>
                                            <Grid2 size={6}>
                                                <DatePickerWithInput
                                                    label={translate('scheduleForm.from')}
                                                    value={startDate}
                                                    onChange={(date) => {
                                                        if (!Date.parse(date)) {
                                                            return;
                                                        }
                                                        setStartDate(date.toDateString());
                                                    }}
                                                />
                                            </Grid2>
                                            &nbsp;&nbsp;&nbsp;
                                            <Grid2>
                                                <TimePickerHour id="startHour" labelId="startHourLabel" value={startHour} onFieldChange={(selectedHour) => setStartHour(selectedHour.id)} />
                                            </Grid2>
                                            <Grid2>
                                                <TimePickerMinutes id="startMinutes" value={startMinutes} onFieldChange={(selected) => setStartMinutes(selected.id)} />
                                            </Grid2>
                                            {/* </strong> */}
                                            {/* </h4> */}
                                        </Grid2>
                                        <Grid2 size={12} container>
                                            <Grid2 size={6}>
                                                <DatePickerWithInput
                                                    label={translate('scheduleForm.to')}
                                                    value={endDate}
                                                    onChange={(date) => {
                                                        if (!Date.parse(date)) {
                                                            return;
                                                        }
                                                        setEndDate(date.toDateString());
                                                    }}
                                                />
                                            </Grid2>
                                            &nbsp;&nbsp;&nbsp;
                                            <Grid2>
                                                <TimePickerHour id="endHour" value={endHour} onChange={(selected) => setEndHour(selected.id)} />
                                            </Grid2>
                                            <Grid2>
                                                <TimePickerMinutes id="endMinutes" value={endMinutes} onChange={(selected) => setEndMinute(selected.id)} />
                                            </Grid2>
                                            {/* </strong>
                                                </h4> */}
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 container alignItems="center" className={classes.marginRow}>
                                        <Grid2 size={{ xs: 4, sm: 2 }}>
                                            <h4>Time Zone</h4>
                                        </Grid2>
                                        <Grid2>
                                            <Field
                                                name="timezone"
                                                component={({ input }) => (
                                                    <Select
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        variant="outlined"
                                                        labelId="timezone"
                                                        id="timezone"
                                                        value={input.value}
                                                        onChange={(e) => {
                                                            setTz(e?.target?.value);
                                                            setValues({
                                                                ...values,
                                                                timezone: e?.target?.value,
                                                            });
                                                        }} // Here we need to call api and modal needs to take from here
                                                        label="Time Zone"
                                                    >
                                                        {TIME_ZONES.map((tz) => (
                                                            <MenuItem
                                                                style={{
                                                                    color: 'black',
                                                                }}
                                                                key={`tz-${tz.id}`}
                                                                value={tz.id}
                                                            >
                                                                {tz.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 className={classes.marginRow} container alignItems="center">
                                        <Grid2 size={{ xs: 4, sm: 2 }}>
                                            <h3>{translate('scheduleForm.people')}</h3>
                                        </Grid2>
                                        &nbsp;&nbsp;&nbsp;
                                        <Grid2
                                            size={{
                                                xs: 8,
                                                sm: 6,
                                                md: 6,
                                                lg: 6,
                                                xl: 6,
                                            }}
                                        >
                                            <Field
                                                required={!values.unlimited}
                                                ignoreError={values.unlimited}
                                                validate={(number) => {
                                                    if (values.unlimited) {
                                                        return undefined;
                                                    }
                                                    if (!number) {
                                                        return translate('form.required');
                                                    }
                                                    if (!/^\d*$/.test(number)) {
                                                        return translate('form.invalid.capacity');
                                                    }
                                                }}
                                                className={classes.capacity}
                                                variant="outlined"
                                                name="capacity"
                                                // value on the form for capacity is null but it doesnt trigger this onChange
                                                value={values.capacity}
                                                disabled={values.unlimited}
                                                defaultValue={values.capacity}
                                            >
                                                {({ input }) => (
                                                    <TextField
                                                        {...input}
                                                        variant="outlined"
                                                        label={translate('scheduleForm.capacity')}
                                                        onChange={(e) => {
                                                            let value = e.target.value < 0 ? '' : e.target.value;

                                                            input.onChange(value);
                                                            setValues({
                                                                ...values,
                                                                [e.target.name]: value,
                                                            });
                                                        }}
                                                        type="number"
                                                    />
                                                )}
                                            </Field>
                                        </Grid2>
                                    </Grid2>

                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.unlimited}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            unlimited: event.target.checked,
                                                        });
                                                    }}
                                                    name="checked"
                                                    color="primary"
                                                />
                                            }
                                            label={translate('scheduleForm.unlimitedCapacity')}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.privateSlot}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            privateSlot: event.target.checked,
                                                        });
                                                    }}
                                                    name="checked"
                                                    color="primary"
                                                />
                                            }
                                            label={translate('scheduleForm.privateSlot')}
                                        />
                                    </div>
                                    <Grid2 className={classes.marginRow} container>
                                        <Grid2
                                            size={{
                                                xs: 8,
                                                sm: 6,
                                                md: 6,
                                                lg: 6,
                                                xl: 6,
                                            }}
                                        >
                                            <Field
                                                required={true}
                                                validate={(number) => {
                                                    if (number === null || number === undefined || number === '') {
                                                        return translate('form.required');
                                                    }
                                                    const numericValue = Number(number);
                                                    if (isNaN(numericValue) || numericValue < 0) {
                                                        return translate('scheduleForm.invalid.price');
                                                    }
                                                    return undefined;
                                                }}
                                                className={classes.capacity}
                                                variant="outlined"
                                                name="price"
                                                value={values.price}
                                                defaultValue={values.price}
                                            >
                                                {({ input }) => (
                                                    <TextField
                                                        {...input}
                                                        variant="outlined"
                                                        label={translate('scheduleForm.price')}
                                                        onChange={(e) => {
                                                            let value = e.target.value < 0 ? '' : e.target.value;

                                                            input.onChange(value);
                                                            setValues({
                                                                ...values,
                                                                [e.target.name]: value,
                                                            });
                                                        }}
                                                        type="number"
                                                    />
                                                )}
                                            </Field>
                                        </Grid2>
                                        <Grid2
                                            style={{
                                                height: '52px',
                                            }}
                                            size={{
                                                xs: 4,
                                                sm: 2,
                                                md: 2,
                                                lg: 2,
                                                xl: 2,
                                            }}
                                        >
                                            <Field
                                                required={true}
                                                className={classes.capacity}
                                                variant="outlined"
                                                label={translate('scheduleForm.currency')}
                                                name="currency"
                                                value={values.currency}
                                                menuItemProps={{
                                                    style: {
                                                        color: 'black',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    },
                                                }}
                                                onFieldChange={(event) => {
                                                    const selectedCurrency = event.target?.value || 'COP';
                                                    setValues({
                                                        ...values,
                                                        currency: selectedCurrency,
                                                    });
                                                }}
                                                component={SelectComponent}
                                                values={[
                                                    {
                                                        id: 'COP',
                                                        name: 'COP',
                                                    },
                                                    {
                                                        id: 'USD',
                                                        name: 'USD',
                                                    },
                                                ]}
                                                defaultValue={values.currency}
                                            />
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 container>
                                        <Grid2 size={12}>
                                            <h4>{translate('scheduleForm.applyEveryWeek')}</h4>
                                            <FormControl>
                                                <Grid2 container>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('MO')}
                                                                        onChange={(e) => handleChecked(e, 'MO')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.mon') : translate('copyScheduleModal.monday')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('TU')}
                                                                        onChange={(e) => handleChecked(e, 'TU')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.tue') : translate('copyScheduleModal.tuesday')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('WE')}
                                                                        onChange={(e) => handleChecked(e, 'WE')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.wed') : translate('copyScheduleModal.wednesday')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('TH')}
                                                                        onChange={(e) => handleChecked(e, 'TH')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.thu') : translate('copyScheduleModal.thursday')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('FR')}
                                                                        onChange={(e) => handleChecked(e, 'FR')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.fri') : translate('copyScheduleModal.friday')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('SA')}
                                                                        onChange={(e) => handleChecked(e, 'SA')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.sat') : translate('copyScheduleModal.saturday')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{
                                                            xs: 6,
                                                            sm: 4,
                                                            md: 2,
                                                        }}
                                                    >
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedWeekDays.includes('SU')}
                                                                        onChange={(e) => handleChecked(e, 'SU')}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={xs ? translate('copyScheduleModal.sun') : translate('copyScheduleModal.sunday')}

                                                            />
                                                        </FormGroup>
                                                    </Grid2>
                                                </Grid2>
                                            </FormControl>
                                        </Grid2>
                                    </Grid2>

                                    {/* ENDS --> */}

                                    <Grid2 container style={{ marginBottom: '24px' }}>
                                        <Grid2 size={12}>
                                            <h4>{translate('scheduleForm.ends')}</h4>
                                            <FormControl>
                                                <Grid2 container>
                                                    {/* //*/}
                                                    <Grid2>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={!selectedRecurrenceEnd}
                                                                        onChange={(value) => setSelectedRecurrenceEnd(false)}
                                                                        inputProps={{
                                                                            'aria-label': 'controlled',
                                                                        }}
                                                                    />
                                                                }
                                                                label={translate('copyScheduleModal.never')}
                                                            />
                                                        </FormGroup>
                                                    </Grid2>

                                                    {/* //UNTIL DATE */}
                                                    <Grid2 container alignItems="center">
                                                        <Grid2 size={{ xs: 4, sm: 2 }}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={selectedRecurrenceEnd.type === 'until'}
                                                                            onChange={(value) => {
                                                                                if (value) {
                                                                                    return setSelectedRecurrenceEnd({ type: 'until', value: '' });
                                                                                }
                                                                            }}
                                                                            inputProps={{
                                                                                'aria-label': 'controlled',
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={translate('copyScheduleModal.on')}
                                                                />
                                                            </FormGroup>
                                                        </Grid2>

                                                        <Grid2 size={{ xs: 8, md: 6 }}>
                                                            <DatePickerWithInput
                                                                disabled={!(selectedRecurrenceEnd.type === 'until')}
                                                                value={values.until}
                                                                onChange={(date) => {
                                                                    if (!Date.parse(date) || Date.now() > date) {
                                                                        return;
                                                                    }
                                                                    //show date on a format, but also save it on utc for back
                                                                    const year = date.getUTCFullYear();
                                                                    const month = String(date.getUTCMonth()).padStart(2, '0');
                                                                    const day = String(date.getUTCDate()).padStart(2, '0');

                                                                    setSelectedRecurrenceEnd({ type: 'until', value: new Date(Date.UTC(year, month, day, 23, 59)) }); //23:59 to include selected date

                                                                    setValues((prev) => ({ ...prev, until: date.toDateString() }));
                                                                }}
                                                            />
                                                        </Grid2>
                                                    </Grid2>

                                                    {/* //AFTER */}

                                                    <Grid2 className={classes.marginRow} container alignItems="center">
                                                        <Grid2 size={{ xs: 4, sm: 2 }}>
                                                            <FormGroup>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={selectedRecurrenceEnd.type === 'count'}
                                                                            onChange={(value) => {
                                                                                if (value) {
                                                                                    return setSelectedRecurrenceEnd({ type: 'count', value: value.count });
                                                                                }
                                                                            }}
                                                                            inputProps={{
                                                                                'aria-label': 'controlled',
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={translate('copyScheduleModal.after')}
                                                                />
                                                            </FormGroup>
                                                        </Grid2>
                                                        <Grid2 size={{ xs: 8, sm: 6 }}>
                                                            <Field
                                                                required={!values.unlimited}
                                                                ignoreError={values.unlimited}
                                                                validate={(number) => {
                                                                    if (selectedRecurrenceEnd.type !== 'count') {
                                                                        return false;
                                                                    }
                                                                    if (!number) {
                                                                        return translate('form.required');
                                                                    }
                                                                    if (!validatePositiveIntegerInput(number)) {
                                                                        return translate('form.invalid.capacity');
                                                                    }
                                                                }}
                                                                className={classes.capacity}
                                                                variant="outlined"
                                                                label={translate('copyScheduleModal.ocurrences')}
                                                                name="count"
                                                                value={values.count}
                                                                onTextFieldChange={(event) => {
                                                                    setValues((prev) => ({ ...prev, count: event.target.value }));
                                                                }}
                                                                component={TextInput}
                                                                disabled={!(selectedRecurrenceEnd.type === 'count')}
                                                                defaultValue={values.count}
                                                            />
                                                        </Grid2>
                                                    </Grid2>
                                                </Grid2>
                                            </FormControl>
                                        </Grid2>
                                    </Grid2>

                                    <div style={{ marginTop: '20px !important' }}>
                                        {clickedSchedule ? (
                                            <Grid2 container spacing={2} justifyContent="space-evenly">
                                                <Grid2 size={4}>
                                                    <Button
                                                        style={{
                                                            width: '100%',
                                                            margin: '0.5rem 0',
                                                            color: '#fff',
                                                            backgroundColor: '#c95546',
                                                        }}
                                                        variant="contained"
                                                        onClick={() => {
                                                            setShowDeleteFutureEventsModal(true);
                                                            setAnchorEl(popoverRef.current);
                                                        }}
                                                    >
                                                        {translate('form.delete')}
                                                    </Button>
                                                </Grid2>
                                                <Grid2 size={4}>
                                                    <Button
                                                        style={{
                                                            width: '100%',
                                                            margin: '0.5rem 0',
                                                            color: '#fff',
                                                            backgroundColor: '#5fb6d9',
                                                        }}
                                                        variant="contained"
                                                        onClick={() => {
                                                                 if (!values.capacity && !values.unlimited) {
                                                                return;
                                                            }
                                                            setShowSaveFutureEventsModal(true);
                                                            setAnchorEl(popoverRef.current);
                                                        }}
                                                    >
                                                        {translate('form.save')}
                                                    </Button>
                                                </Grid2>
                                            </Grid2>
                                        ) : (
                                            <Button type="submit" className={classes.thisWeek}>
                                                {translate('form.save')}
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            )}
                        />
                    </>
                    {/* )} */}
                </DialogContent>
            </Popover>
            <FutureEventsModal
                show={showDeleteFutureEventsModal}
                onClose={onCloseFutureEventsModal}
                x={x}
                y={y}
                anchorEl={anchorEl}
                save={false}
                onDelete={async (all = false, thisAndFollowing = false) => {
                    onCloseFutureEventsModal();
                    let data = loadDateIntoValues();
                    if (!data.startUTC) {
                        return;
                    }
                    if (thisAndFollowing) {
                        // update del original

                        const startDate = new Date(data.startUTC);

                        const year = startDate.getUTCFullYear();
                        const month = startDate.getUTCMonth();
                        const day = startDate.getUTCDate() - 1; //-1 a day before

                        const oldByweeekday = RRule.fromString(clickedEventValues.rRule).options.byweekday;

                        const rRule = new RRule({
                            freq: RRule.WEEKLY,
                            byweekday: oldByweeekday,
                            until: new Date(Date.UTC(year, month, day, 23, 59)),
                        });

                        const result = await updateExperienceSchedule({
                            expId,
                            schId: values.id,
                            all: true,
                            ...clickedEventValues,
                            rRule: rRule.toString().substring(6),
                        });

                        if (result) {
                            return onSave({ data: { ...data, all: true, repeat: true }, event: 'onDelete' });
                        }
                    } else {
                        const result = await deleteExperienceSchedule({
                            expId,
                            schId: values.id,
                            all,
                            ...data,
                        });
                        if (result) {
                            onSave({ data: { ...data, all }, event: 'onDelete' });
                        }
                    }
                }}
            />
            <FutureEventsModal
                show={showSaveFutureEventsModal}
                onClose={onCloseFutureEventsModal}
                x={x}
                y={y}
                anchorEl={anchorEl}
                save={true}
                onSave={async (all = false, thisAndFollowing = false) => {
                    onCloseFutureEventsModal();
                    let data = loadDateIntoValues();
                    if (!data.startUTC) {
                        return;
                    }

                    if (thisAndFollowing) {
                        //modificar evento para que termine acá -> update rrule

                        const startDate = new Date(data.startUTC);

                        const year = startDate.getUTCFullYear();
                        const month = startDate.getUTCMonth();
                        const day = startDate.getUTCDate() - 1; //-1 a day before

                        const newByweeekday = RRule.fromString(values.rRule).options.byweekday;
                        const oldByweeekday = RRule.fromString(clickedEventValues.rRule).options.byweekday;

                        const rRule = new RRule({
                            freq: RRule.WEEKLY,
                            byweekday: oldByweeekday,
                            until: new Date(Date.UTC(year, month, day, 23, 59)),
                        });

                        const result = await updateExperienceSchedule({
                            expId,
                            schId: values.id,
                            all: true,
                            ...clickedEventValues,
                            rRule: rRule.toString().substring(6),
                        });

                        // now generate the new event this and future

                        let newRrule;
                        if (!selectedRecurrenceEnd) {
                            newRrule = new RRule({
                                freq: RRule.WEEKLY,
                                byweekday: newByweeekday,
                            });
                        }

                        if (selectedRecurrenceEnd.type === 'count') {
                            newRrule = new RRule({
                                freq: RRule.WEEKLY,
                                byweekday: newByweeekday,
                                count: values.count,
                            });
                        }

                        if (selectedRecurrenceEnd.type === 'until') {
                            newRrule = new RRule({
                                freq: RRule.WEEKLY,
                                byweekday: newByweeekday,
                                until: selectedRecurrenceEnd.value,
                            });
                        }

                        const success = await addExperienceSchedule({
                            experienceDetailId: expId,
                            ...data,
                            rRule: newRrule.toString().substring(6),
                        });

                        if (result) {
                            return onSave({ data: { ...data, all }, event: 'onUpdate' });
                        }
                    } else {
                        const result = await updateExperienceSchedule({
                            expId,
                            schId: values.id,
                            all,
                            ...data,
                        });
                        if (result) {
                            onSave({ data: { ...data, all }, event: 'onUpdate' });
                        }
                    }
                }}
            />
        </>
    );
};
