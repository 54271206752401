import React, { useState, useEffect } from 'react';

import { Form, Field } from 'react-final-form';
import { Grid2, Button, Typography, Divider, Snackbar, InputLabel, TextField } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { ExperiencerService } from 'core/api/services/experiencer';
import { useTranslation } from 'core/contexts/localization';
import { useExperiencers } from 'core/contexts/experiencer';
import { useAuth } from 'core/contexts/auth';
import Dialog from 'core/libs/core-ui/components/Dialog';
import EditIcon from 'assets/icons/edit-white.svg';

import TextInput from 'components/Input/Text';
import PhoneNumber from 'components/Input/PhoneNumber';
import TextInputDescription from './TextInput';
import { HeaderAvatar, TextFieldAdapter, AutocompleteAdapter, SaveChangesModal } from '../components';
import ImageCropper from '../components/ImageCropper';

import { useStyles } from './styles';
import { nationalityList } from '../mocks';
import BasicTable from 'core/libs/core-ui/components/Table';
import { Delete } from '@mui/icons-material';
import { useRefreshToken } from 'core/contexts/refreshToken';
import { toast } from 'react-toastify';
import { DateInput } from 'components/Input/Date';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import { DateTime } from 'luxon';

export const ExperiencerProfilePage = () => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const { update } = useExperiencers();
    const { userInfo: info, getUserInfoSync } = useAuth();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [userInfo, setUserInfo] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [file, setFile] = React.useState();
    const [image, setImage] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [profileChanged, setProfileChanged] = React.useState(false);
    const [modalVisible, setmodalVisible] = useState(false);
    const [userData, setUserData] = React.useState({});
    const [countryCode, setCountryCode] = React.useState('');
    const [tokenToDelete, setTokenToDelete] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const { getRefreshToken, createRefreshToken, deleteRefreshToken, token, loading } = useRefreshToken();
    const [copiedTokenId, setCopiedTokenId] = useState(null);

    const onSubmit = async (values) => {
        values = { experiencer: userInfo };
        const valuesClon = { ...values };
        delete valuesClon.experiencer.pictureURL;
        if (file) await ExperiencerService.updateAvatar(info?.experiencer?.id, file);
        setSaved(true);
        if (profileChanged) await update(valuesClon);
        const userInfoSync = await getUserInfoSync();
        userInfoSync.experiencer.email = userInfoSync?.email;
        setUserInfo(userInfoSync?.experiencer);
        setCountryCode(userInfoSync?.experiencer?.mobilePhoneCountryCode);
        setCanEdit(false);
        setOpenSnackBar(true);
        setUserData(userInfoSync?.experiencer);
    };

    useEffect(() => {
        getRefreshToken();
    }, []);

    useEffect(() => {
        getUserInfoSync().then(() => {
            if (info.experiencer) info.experiencer.email = info?.email;
            setUserData(info?.experiencer);
            setCountryCode(info?.experiencer?.mobilePhoneCountryCode);
        });
    }, []);

    useEffect(() => {
        if (info.experiencer) info.experiencer.email = info?.email;
        setUserData(info?.experiencer);
        setCountryCode(info?.experiencer?.mobilePhoneCountryCode);
    }, [info]);

    const prettifyTokens = (token = []) => {
        return token.map((token) => {
            const handleCopy = (refreshToken, tokenId) => {
                navigator.clipboard.writeText(refreshToken);
                setCopiedTokenId(tokenId);

                setTimeout(() => setCopiedTokenId(false), 2000);
            };
            const date = new Date(token.expireOn);
            const dateFormatter = DateTime.fromJSDate(date, { locale: `${translate('profile.date_expiry')}` });

            return {
                ...token,
                token: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
                        <span style={{ alignContent: 'center' }}>{`${token.refreshToken.slice(0, 20)}...`}</span>
                        {copiedTokenId === token.id ? <CheckIcon className={classes.tableButton} /> : <ContentCopyIcon onClick={() => handleCopy(token.refreshToken, token.id)} className={classes.tableButton} />}
                    </div>
                ),
                description: token.description === null ? '' : `${token.description}`,
                expireOn: token.expireOn === null ? '' : `${dateFormatter.toFormat('MMMM dd, yyyy')}`,
                delete: (
                    <Delete
                        onClick={() => {
                            setTokenToDelete(token.id);
                            setOpenDelete(true);
                        }}
                        className={classes.tableButton}
                    />
                ),
            };
        });
    };

    const onSubmitDeleteToken = async () => {
        if (tokenToDelete) {
            try {
                setOpenDelete(false);
                await deleteRefreshToken(tokenToDelete);
                setTokenToDelete(null);
            } catch (err) {
                toast.error(err, {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            getRefreshToken();
        }
    };

    const onSubmitToken = async (values) => {
        const valuesClon = { ...values };
        valuesClon.expireOn = valuesClon.expireOn ? DateTime.fromJSDate(valuesClon.expireOn).setZone('UTC', { keepLocalTime: true }).toISO().split('T')[0] : null;
        try {
            await createRefreshToken(valuesClon);
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
            getRefreshToken();
        }
    };

    const renderAddForm = () => {
        return (
            <Form
                onSubmit={onSubmitToken}
                initialValues={''}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid2 container direction="column" style={{ height: 'fit-content' }}>
                            <Grid2 container direction="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <Grid2>
                                    <h3 style={{ fontWeight: '700' }}>{translate('profile.title_create_refresh_token')}</h3>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            form.reset({ duration: null, currency: null, price: null });
                                        }}
                                    >
                                        {translate('profile.button_clear')}
                                    </Button>
                                </Grid2>
                            </Grid2>
                            <Grid2 style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>{translate('profile.description')}:</InputLabel>
                                <Field name="description">
                                    {({ input }) => (
                                        <TextField
                                            {...input}
                                            variant="outlined"
                                            onChange={(e) => {
                                                let value = e.target.value < 0 ? 0 : e.target.value;
                                                if (value === '') value = null;
                                                input.onChange(value);
                                            }}
                                        />
                                    )}
                                </Field>
                            </Grid2>
                            <Grid2 style={{ width: '100%', margin: '.5rem 2px' }}>
                                <InputLabel>{translate('profile.table_expiry')}:</InputLabel>
                                <Field
                                    name="expireOn"
                                    component={DateInput}
                                    disablePast={true}
                                    onChange={(date) => {
                                        if (!Date.parse(date)) {
                                            return;
                                        }
                                    }}
                                />
                            </Grid2>
                            <Grid2
                                container
                                direction="row"
                                style={{
                                    height: '50px',
                                    marginTop: '10px',
                                }}
                            >
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            setOpenAdd(false);
                                            return form.reset(e);
                                        }}
                                        className={classes.CancelButton}
                                    >
                                        {translate('profile.btn_cancel')}
                                    </Button>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        onClick={(e) => {
                                            setOpenAdd(false);
                                            return handleSubmit(e);
                                        }}
                                        className={classes.SaveButton}
                                    >
                                        {translate('profile.btn_submit')}
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </form>
                )}
            />
        );
    };

    return (
        <div>
            <div className={classes.profileDiv}>
                <div className={classes.leftColumn} style={{ minHeight: isSmall ? 'initial' : '100vh' }}>
                    <div className={classes.leftColumnContent}>
                        <SaveChangesModal profileChanged={profileChanged && canEdit} saved={saved} modalVisible={modalVisible} setCanEdit={setCanEdit} />
                        <ImageCropper
                            setFile={setFile}
                            setImage={setImage}
                            setCanEdit={setCanEdit}
                            // children={
                            //     <HeaderAvatar
                            email={info.email}
                            userName={info?.experiencer?.name}
                            picture={
                                image || userData === undefined || !userData || !userData.pictureURL
                                    ? image || '' // deberia ir una URL default por ej
                                    : `${process.env.REACT_APP_CDN_URL}${userData?.pictureURL}`
                            }
                            // onClick={() => {
                            //     setCanEdit(true);
                            // }}
                            // />
                            // }
                        />
                    </div>
                </div>
                <div className={classes.rightColumn}>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            ...userData,
                        }}
                        render={({ handleSubmit, form }) => (
                            <form id="form" onSubmit={handleSubmit}>
                                <div>
                                    <Grid2 container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid2>
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                }}
                                                variant="h5"
                                                className={classes.text}
                                            >
                                                {translate('profile.edit_profile_data')}
                                            </Typography>
                                        </Grid2>
                                        <Grid2>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setCanEdit(true);
                                                }}
                                                style={{
                                                    height: 40,
                                                    margin: 'inherit',
                                                }}
                                                // className="triper-button bg-green-300"
                                                startIcon={<img alt="" src={EditIcon} width={20} />}
                                            >
                                                {isSmall ? null : translate('profile.edit')}
                                            </Button>
                                        </Grid2>
                                    </Grid2>

                                    <Divider />

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '8px',
                                        }}
                                    >
                                        <div className={classes.sectionTitle}>
                                            <Typography variant="h6" className={classes.text}>
                                                {translate('profile.personal_data')}
                                            </Typography>
                                        </div>
                                        <Grid2 container spacing={3}>
                                            <Grid2 size={6}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.name = value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    id="name"
                                                    name="name"
                                                    component={TextInput}
                                                    hintText={translate('profile.name')}
                                                    label={translate('profile.name')}
                                                    disabled={!canEdit}
                                                />
                                            </Grid2>
                                            <Grid2 size={6}>
                                                <Field name="email" component={TextFieldAdapter} hintText={translate('profile.email')} label={translate('profile.email')} disabled />
                                            </Grid2>
                                        </Grid2>
                                        <Grid2 container spacing={3}>
                                            <Grid2 size={6}>
                                                <Field
                                                    id="phone"
                                                    onFieldChange={(value, country) => {
                                                        userInfo.mobilePhone = value;
                                                        userInfo.mobilePhoneCountryCode = country;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    name="mobilePhone"
                                                    component={PhoneNumber}
                                                    hintText={translate('profile.phone')}
                                                    label={translate('profile.phone')}
                                                    disabled={!canEdit}
                                                    initialCountry={countryCode || 'us'}
                                                />
                                            </Grid2>
                                        </Grid2>
                                        <Grid2 container spacing={3}>
                                            <Grid2 size={4}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.addressCountry = value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    startValue={userInfo?.addressCountry}
                                                    id="triper.country"
                                                    name="addressCountry"
                                                    component={AutocompleteAdapter}
                                                    options={nationalityList}
                                                    getOptionLabels={(option) => option.en_short_name}
                                                    hintText={translate('profile.country')}
                                                    label={translate('profile.country')}
                                                    disabled={!canEdit}
                                                />
                                            </Grid2>
                                            <Grid2 size={4}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.addressCity = value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    id="triper.city"
                                                    name="addressCity"
                                                    component={TextInput}
                                                    hintText={translate('profile.city')}
                                                    label={translate('profile.city')}
                                                    disabled={!canEdit}
                                                />
                                            </Grid2>
                                            <Grid2 size={4}>
                                                <Field
                                                    onFieldChange={(value) => {
                                                        userInfo.addressStreet = value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    initialValue={info?.experiencer?.addressStreet}
                                                    id="triper.address"
                                                    name="addressStreet"
                                                    component={TextInput}
                                                    hintText={translate('profile.address')}
                                                    label={translate('profile.address')}
                                                    disabled={!canEdit}
                                                />
                                            </Grid2>
                                        </Grid2>
                                        <Grid2 container spacing={3}>
                                            <Grid2 size={12}>
                                                <Field
                                                    style={{
                                                        marginBottom: '2em',
                                                    }}
                                                    onFieldChange={(value) => {
                                                        userInfo.description = value;
                                                        setUserInfo(userInfo);
                                                        setProfileChanged(true);
                                                    }}
                                                    multiline
                                                    minRows={4}
                                                    id="description"
                                                    name="description"
                                                    component={TextInputDescription}
                                                    label={translate('profile.description')}
                                                    disabled={!canEdit}
                                                    maxCharacters={500}
                                                    initialCharacters={userData?.description?.length || 0}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </div>
                                </div>

                                <Grid2 container spacing={3} justifyContent="flex-end" alignContent="flex-end">
                                    <Grid2>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={!canEdit}
                                            type="button"
                                            onClick={(e) => {
                                                setCanEdit(false);
                                                return form.reset(e);
                                            }}
                                        >
                                            {translate('profile.btn_cancel')}
                                        </Button>
                                    </Grid2>
                                    <Grid2>
                                        <Button
                                            onClick={(e) => {
                                                setCanEdit(false);
                                                return handleSubmit(e);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={!canEdit}
                                            type="submit"
                                        >
                                            {translate('profile.btn_submit')}
                                        </Button>
                                    </Grid2>
                                </Grid2>
                            </form>
                        )}
                    />
                    <br />
                    <Grid2 container display="flex" direction={'column'}>
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '10px',
                            }}
                            variant="h5"
                            className={classes.text}
                        >
                            {translate('profile.title_create_refresh_token')}
                        </Typography>
                        <Divider />

                        {loading ? (
                            <div className={classes.loaderContainer}>
                                <div>
                                    <CircularIndeterminate centered={true} />
                                </div>
                            </div>
                        ) : (
                            <Grid2 size={12}>
                                <>
                                    <BasicTable
                                        columns={[
                                            { align: 'left', val: 'token', label: 'Token', style: { width: '175px' } },
                                            { val: 'description', label: `${translate('profile.table_description')}` },
                                            { val: 'expireOn', label: `${translate('profile.table_expiry')}`, style: { width: '200px' } },
                                            { val: 'delete', label: ' ', style: { width: '37px' } },
                                        ]}
                                        values={prettifyTokens(token)}
                                    />
                                    <Dialog
                                        open={openDelete}
                                        dialogTitle={'Attention!'}
                                        dialogContent={`Are you sure you want to remove the Token`}
                                        handleClose={() => {
                                            setTokenToDelete(null);
                                            setOpenDelete(false);
                                        }}
                                        handleSubmit={() => {
                                            onSubmitDeleteToken();
                                        }}
                                    />
                                </>
                            </Grid2>
                        )}
                        <Grid2 container display="flex" justifyContent={'end'}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenAdd(true);
                                }}
                                style={{
                                    height: 40,
                                    margin: 'inherit',
                                    margin: '20px 0',
                                    width: '100px',
                                }}
                                type="submit"
                            >
                                {translate('profile.button_create_refresh_token')}
                            </Button>
                        </Grid2>
                    </Grid2>
                    <Dialog
                        open={openAdd}
                        dialogContent={renderAddForm()}
                        handleClose={() => {
                            setOpenAdd(true);
                        }}
                        hideTitle
                        hideBack
                        hideSubmit
                    />
                </div>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={1500}
                onClose={() => setOpenSnackBar(false)}
                message={translate('profile.update_notification')}
            />
        </div>
    );
};

ExperiencerProfilePage.displayName = 'ExperiencerProfilePage';

export default ExperiencerProfilePage;
